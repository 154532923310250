<template>
  <section
    class="invoice-preview-wrapper"
  >

    <b-row
      v-if="processo"
      class="invoice-preview"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="false"
          :paginate-elements-by-height="1200"
          :pdf-quality="2"
          :pdf-format="'a4'"
          :pdf-orientation="'portrait'"
          :pdf-content-width="'100%'"
          :manual-pagination="true"
          :html-to-pdf-options="htmlToPdfOptions"
          @beforeDownload="beforeDownload($event)"
          @hasDownloaded="hasDownloaded($event)"
        >

          <LayoutProcesso
            slot="pdf-content"
            :processo="processoEmail"
            :intervencao="intervencaoEmail"
            :lista-processo="listaProcesso"
            :lista-intervencoes="listaIntervencoes"
          />

        </vue-html2pdf>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="enviaEmail()"
          >
            Enviar Processo
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="downloadPdf"
          >
            Download
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'editar-processo-id', params: { id: $route.params.id } }"
          >
            Editar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>

</template>

<script>
import {
  BRow, BCol, BCard, BButton,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import VueHtml2pdf from 'vue-html2pdf'
import router from '@/router'
import swal from 'sweetalert2'
import LayoutProcesso from '../LayoutProcesso/LayoutProcesso.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueHtml2pdf,
    LayoutProcesso,
    BRow,
    BCol,
    BCard,
    BButton,
  },
  props: {
    idProcesso: Object,
  },
  data() {
    return {
      isDownload: false,
      generatedPdf: null,
      invoiceDescription: [
        {
          taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        },
        {
          taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        },
      ],
      valorPackHoras: {
        pack10: {
          titulo: 'Pack 10', descricao: 'Até 10 horas', valor: 40,
        },
        pack50: {
          titulo: 'Pack 50', descricao: 'Até 50 horas', valor: 35,
        },
        packPlus: {
          titulo: 'Pack Plus', descricao: 'Mais de 50 horas', valor: 30,
        },
      },
      userSigned: JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem('userSigned')))),
      processo: { },
      cliente: { },
      seriesProcesso: { series: [] },
      serieSelecionada: { serie: '', key: '' },
      tipoProcesso: { tipoProcessos: [] },
      tipoProcessoSelecionado: { tipoProcesso: '', key: '' },
      prioridadeProcesso: { prioridades: [] },
      prioridadeSelecionada: { prioridade: '', key: '' },
      tecnicoProcesso: { tecnicos: [] },
      tecnicoSelecionado: { tecnico: '', key: '' },
      estadoProcesso: { estados: [] },
      estadoSelecionado: { estado: '', key: '' },
      documentosProcesso: { },
      listaProcesso: [],
      listaIntervencoes: [],
      processoEmail: {},
      intervencaoEmail: [],
      colunasProcesso: [
        { key: 'descricaoProb', label: 'Problema Reportado' },
      ],
      vendedor: { },
      moeda: { },
      contrato: { },
      htmlToPdfOptions: {
        margin: [0.5, 0, 1, 0],
        filename: '',
        enableLinks: false,
        enableDownload: false,
        pagebreak: { mode: ['css', 'legacy'] },
        html2canvas: {
          scale: 2, // higher quality
          windowWidth: 1200, // simulate a browser size that causes the page's responsive CSS to output a pleasing layout in the rendered PDF
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
    }
  },

  async mounted() {
    await this.abrirRelatorio()
  },
  methods: {
    async beforeDownload({ html2pdf, options, pdfContent }) {
      if (this.isDownload === true) {
        await html2pdf().set(options).from(pdfContent).toPdf()
          .get('pdf')
          .then(pdf => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i += 1) {
              pdf.setPage(i)
              pdf.setFontSize(10)
              pdf.setTextColor(150)
              pdf.text(`Pág. ${i} de ${totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            }
            this.generatedPdf = pdf.output('dataurlstring')
          })
          .save()
      } else if (this.isDownload === false) {
        await html2pdf().set(options).from(pdfContent).toPdf()
          .get('pdf')
          .then(pdf => {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i += 1) {
              pdf.setPage(i)
              pdf.setFontSize(10)
              pdf.setTextColor(150)
              pdf.text(`Pág. ${i} de ${totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            }
            this.generatedPdf = pdf.output('dataurlstring')
          })
          .get()
      }
    },

    async downloadPdf() {
      this.isDownload = true
      await this.$refs.html2Pdf.generatePdf()
    },

    async criaPDFAbertura() {
      try {
        this.isDownload = false
        await this.$refs.html2Pdf.generatePdf()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async abrirRelatorio() {
      try {
        await this.getProcesso()
        await this.getIntervencoes()
        await this.criaPDFAbertura()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async enviaEmail() {
      try {
        await axios.post(`/api/v1/processos/${this.processo.id}/enviaRelatorio`, {
          pdf: this.generatedPdf.split('base64,')[1],
          nomePdf: this.nomeFicheiro,
        })
        this.autoClose()
      } catch (error) {
        console.error('Erro ao baixar o relatório em PDF', error)
      }
    },

    autoClose() {
      swal.fire({
        title: 'Email enviado com sucesso !',
        timer: 3000,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    printInvoice() {
      window.print()
    },
    async getProcesso() {
      this.listaProcesso = []
      try {
        const processoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
        const apiUrl = `/api/v1/processos/${processoId}/complete`

        const res = await axios.get(apiUrl)

        this.processo = res.data
        this.processoEmail = res.data
        this.listaProcesso.push(res.data)
        this.nomeFicheiro = res.data.processo
        this.htmlToPdfOptions.filename = res.data.processo
      } catch (error) {
        // Lide com erros aqui, se necessário
      }

      this.$forceUpdate()
    },
    async getIntervencoes() {
      try {
        this.listaIntervencoes = []
        const response = await axios.get(`/api/v1/intervencoes/tipodoc/${this.processo.tipoDoc}/serie/${this.processo.serie}/numdoc/${this.processo.numProcesso}/complete`)
        this.intervencao = response.data
        this.listaIntervencoes.push(response.data)
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },
  },
}

</script>
  <style lang="scss">
  @media print {

    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }

        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }

              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }

      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
