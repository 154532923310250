<template>
  <section slot="pdf-content">
    <section
      class="invoice-preview-wrapper"
    >

      <b-card
        no-body
        class="invoice-preview-card"
      >

        <!-- Header -->
        <b-card-body
          class="pb-0"
          style="padding-left: 2.5rem;padding-right: 2.5rem;"
        >

          <div
            class="d-flex justify-content-between flex-md-row flex-column mt-0 (min-width: 100px)"
            style="margin: 1.45rem 0;"
          >

            <!-- Header: Left Content -->
            <div>
              <div
                style="display: flex; align-items: left; margin-bottom: 1.9rem;"
              >
                <!-- <logo /> -->
                <h3
                  class="text-primary"
                  style="font-size: 16px; font-weight: bold; letter-spacing: -0.54px;  margin-bottom: 0;"
                >
                  Paulo Lopes
                </h3>
              </div>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
               
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                
              </p>
              <p
                class="card-text mb-0"
                style="font-size: 12px;"
              >
                Tel: 916 498 954 | info.paulolopes@gmail.com
              </p>
            </div>

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
              <h4 style="font-size: 12px;  text-align: left;">
                Processo
                <span style="font-size: 12px;">#{{ processo.processo }}</span>
              </h4>
              <div style="display: flex; align-items: center;">
                <p style="font-size: 12px; margin-bottom: 0;">
                  Aberto em:
                </p>
                <p style="margin-left: 0.5rem; font-size: 12px; margin-bottom: 0;">
                  {{ new Date(processo.dataHoraAbertura).toLocaleString() }}
                </p>
              </div>
              <div style="display: flex; align-items: center; margin-bottom: 0.5rem;">
                <p style="margin-bottom: 0; font-size: 12px;">
                  Estado:
                </p>
                <p style="margin-left: 0.5rem; font-size: 12px; margin-bottom: 0;">
                  {{ processo.descricaoEstado }}
                </p>
              </div>
            </div>
          </div>
        </b-card-body>

        <!-- Invoice Client & Payment Details -->
        <b-card-body
          v-if="processo.cliente"
          class="pt-0"
          style="padding-left: 2.5rem; padding-right: 2.5rem;"
        >
          <b-row style="margin: 1.45rem 0;">

            <!-- Col: Invoice To -->
            <b-col
              cols="12"
              xl="8"
              class="p-0"
            >
              <h6
                class="mb-1"
                style="font-size: 12px;"
              >
                Cliente:
              </h6>
              <h6
                class="mb-25"
                style="font-size: 12px;"
              >
                {{ processo.codigoCliente }} - {{ processo.nomeFiscalCliente }}
              </h6>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                {{ processo.moradaCliente }}
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                {{ processo.codigoPostalCliente }} {{ processo.localidadeCliente }}
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                NIF: {{ processo.nifCliente }}
              </p>
            </b-col>

            <!-- Col: Payment Details -->
            <b-col
              v-if="processo.processaFaturacaoContrato===0"
              cols="12"
              xl="4"
              class="p-0"
            >
              <h6
                class="mb-1"
                style="font-size: 12px;"
              >
                Faturação:
              </h6>
              <h6
                class="mb-25"
                style="font-size: 12px;"
              >
                Ao abrigo do contrato: {{ processo.referenciaContrato }}
              </h6>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Horas Contratadas: {{ convertToMs(processo.totalHorasContrato) }} Horas
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Horas Utilizadas: {{ convertToMs(processo.horasGastasContrato) }} Horas
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Horas Disponíveis: {{ convertToMs(processo.horasDisponiveisContrato) }} Horas
              </p>
            </b-col>
            <b-col
              v-else
              cols="12"
              xl="4"
              class="p-0"
            >
              <h6
                class="mb-1"
                style="font-size: 12px;"
              >
                Faturação:
              </h6>
              <h6
                class="mb-25"
                style="font-size: 12px;"
              >
                Sem contacto de Horas
              </h6>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Faturado no fim do processo
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Valor: 55€ por Hora
              </p>
              <p
                class="card-text mb-25"
                style="font-size: 12px;"
              >
                Tempo: taxado de 15 em 15 minutos
              </p>
            </b-col>
          </b-row>
        </b-card-body>

        <!--/ pricing plan cards -->

        <b-table-lite
          responsive
          :items="listaProcesso"
          :fields="colunasProcesso"
        >
          <template #cell(processo)="data">
            <b-card-text
              class="text-nowrap"
              v-html="data.item.descricaoProb"
            />
          </template>
        </b-table-lite>

        <hr style="margin: 1.45rem 0">

        <b-card-body
          v-for="(intervencao, index) in listaIntervencoes[0]"
          :key="intervencao.id"
          class="pt-0"
          style="padding-left: 2.5rem; padding-right: 2.5rem;"
        >
          <section class="pdf-item">
            <b-row>
              <p style="font-size: 12px; margin-bottom: 0; font-weight: bold;">
                Intervenção Nº {{ intervencao.interv }}
              </p>

            </b-row>
            <b-row>
              <b-col
                cols="4"
                xl="4"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Início: {{ new Date(intervencao.dataHoraInicio).toLocaleString() }}
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>
              <b-col
                cols="4"
                xl="4"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Fim: {{ new Date(intervencao.dataHoraFim).toLocaleString() }}
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>

            </b-row>
            <b-row>
              <b-col
                cols="4"
                xl="4"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Duração: {{ intervencao.duracaoReal }} minutos
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>

              <b-col
                cols="5"
                xl="5"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Imputado ao contrato: {{ intervencao.duracao }} minutos
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>
              <b-col
                cols="3"
                xl="3"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Técncio: {{ intervencao.nomeTecnicoIntervIntervcao }}
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>

              <b-col
                cols="4"
                xl="4"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Estado: {{ intervencao.descricaoEstadoIntervencao }}
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>
              <b-col
                cols="4"
                xl="4"
                class="p-0"
              >
                <p style="font-size: 12px; margin-bottom: 0;">
                  Tipo Intervenção: {{ intervencao.descricaoTipoIntervIntervcao }}
                </p>
              <!-- Coloque aqui os campos específicos da intervenção -->
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                xl="12"
                class="p-0"
              >
                <h6 style="font-size: 12px;">
                  <h5 style="font-size: 12px;">
                    Resposta:
                  </h5>
                  <span v-html="intervencao.descricaoResp" />
                </h6>

              <!-- Coloque aqui os campos específicos da faturação -->
              </b-col>
            </b-row>
          </section>
        </b-card-body>
        <section class="pdf-item">
          <div
            v-if="processo.processaFaturacaoContrato===1"
            class="text-center"
          >
            <h3 class="mt-3">
              Contrato de Horas
            </h3>
            <p
              class="mb-0"
              style="font-size: 13px;"
            >
              Adequira o contrato que melhor se adequa à necessidades da sua empresa</p>

          </div>
          <b-row
            v-if="processo.processaFaturacaoContrato===1"
            class="pricing-card"
          >
            <b-col
              offset-sm-2
              sm="10"
              md="12"
              offset-lg="2"
              lg="10"
              class="mx-auto"
            >
              <b-row>
                <b-col md="4">
                  <b-card
                    align="center"
                  >
                    <h4>{{ valorPackHoras.pack10.titulo }}</h4>
                    <b-card-text>
                      {{ valorPackHoras.pack10.descricao }}
                    </b-card-text>
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                        <span
                          class="pricing-basic-value font-weight-bolder text-primary"
                          style="font-size: 30px;"
                        >{{ valorPackHoras.pack10.valor }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/hora</sub>
                      </div>

                    </div>
                  </b-card>
                </b-col>
                <b-col md="4">
                  <b-card
                    align="center"
                  >
                    <h4>{{ valorPackHoras.pack50.titulo }}</h4>
                    <b-card-text>{{ valorPackHoras.pack50.descricao }}</b-card-text>

                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                        <span
                          class="pricing-basic-value font-weight-bolder text-primary"
                          style="font-size: 30px;"
                        >{{ valorPackHoras.pack50.valor }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/hora</sub>
                      </div>
                    </div>

                  </b-card>
                </b-col>
                <b-col md="4">
                  <b-card
                    align="center"
                  >
                    <h4>{{ valorPackHoras.packPlus.titulo }}</h4>
                    <b-card-text>{{ valorPackHoras.packPlus.descricao }}</b-card-text>
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                        <span
                          class="pricing-basic-value font-weight-bolder text-primary"
                          style="font-size: 30px;"
                        >{{ valorPackHoras.packPlus.valor }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/hora</sub>
                      </div>

                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </section>
      </b-card>
    </section>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
  },
  props: {
    processo: Object,
    listaProcesso: Array,
    listaIntervencoes: Array,
  },
  data() {
    return {
      intervencao: {
        id: '',
        processoID: '',
        interv: '',
        tipoInterv: '',
        descricaoResp: '',
        duracao: '',
        duracaoReal: '',
        dataHoraInicio: '',
        dataHoraFim: '',
        tecnico: '',
        estadoAnt: '',
        estado: '',
        moeda: '',
        utilizador: '',
        imputadoContrato: '',
        facturado: '',
        tempoEfetivo: '',
        duracaoTempoEfetivo: '',
      },
      valorPackHoras: {
        pack10: {
          titulo: 'Pack 10', descricao: 'Até 10 horas', valor: 40,
        },
        pack50: {
          titulo: 'Pack 50', descricao: 'Até 50 horas', valor: 35,
        },
        packPlus: {
          titulo: 'Pack Plus', descricao: 'Mais de 50 horas', valor: 30,
        },
      },
      userSigned: JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem('userSigned')))),
      cliente: { },
      seriesProcesso: { series: [] },
      serieSelecionada: { serie: '', key: '' },
      tipoProcesso: { tipoProcessos: [] },
      tipoProcessoSelecionado: { tipoProcesso: '', key: '' },
      prioridadeProcesso: { prioridades: [] },
      prioridadeSelecionada: { prioridade: '', key: '' },
      tecnicoProcesso: { tecnicos: [] },
      tecnicoSelecionado: { tecnico: '', key: '' },
      estadoProcesso: { estados: [] },
      estadoSelecionado: { estado: '', key: '' },
      documentosProcesso: { },
      colunasProcesso: [
        { key: 'descricaoProb', label: 'Problema Reportado' },
      ],
      colunasIntervencao: [
        { key: 'descricaoResp', label: 'Resposta' },
        { key: 'interv', label: 'interv' },
        { key: 'dataHoraInicio', label: 'dataHoraInicio' },
        { key: 'dataHoraFim', label: 'dataHoraFim' },
      ],
      vendedor: { },
      moeda: { },

      contrato: { },
    }
  },
  created() {
  },

  methods: {

    convertToMs(number) {
      let sign = (number >= 0) ? 1 : -1

      // Set positive value of number of sign negative
      // eslint-disable-next-line no-param-reassign
      number *= sign

      // Separate the int from the decimal part
      const hour = Math.floor(number)
      let decpart = number - hour

      const min = 1 / 60
      // Round to nearest minute
      decpart = min * Math.round(decpart / min)

      let minute = `${Math.floor(decpart * 60)}`

      // Add padding if need
      if (minute.length < 2) {
        minute = `0${minute}`
      }

      // Add Sign in final result
      // eslint-disable-next-line eqeqeq
      sign = sign == 1 ? '' : '-'

      // Concate hours and minutes
      const time = `${sign + hour}:${minute}`

      return time
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
